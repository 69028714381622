import React from 'react'
import Layout from '../components/layout'
import Gatsbyjs from '../components/categories/gatsbyjs'
import Seo from '../components/seo'

const GatsbyjsPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Gatsbyjs />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Gatsbyjs Freelancers | Codestaff'
  const description =
    'Hire the best Gatsbyjs freelancers at Codestaff. Get the top 1% of Gatsbyjs professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default GatsbyjsPage
